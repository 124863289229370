import React from "react"
import { EmailForm } from "./EmailForm"

export function Contact() {
   return (
      <>
      <div className="mb-5 pb-5">
        <EmailForm/>
        </div>
      </>
   )
}